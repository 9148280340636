import { Options } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES, MARKS, Node } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React, { ReactNode } from "react"
import styled from "styled-components"
import { mapEmbeddedEntryToButtonLink } from "../../../contentful/options/rich-text"
import VideoResolver from "../../../resolvers/video/video.resolver"
import {
  AppFunctionComponent,
  RenderRichText,
  ResponsiveImage,
  Video,
} from "../../../types"
import {
  NeutralRawLink,
  RawLink,
} from "../../../ui/components/link/link.component"
import Zoomable from "../../../ui/components/zoomable/zoomable.component"
import {
  annotationStyles,
  footnotesAnchors,
  Quote,
} from "../../common/general-styles.component"
import {
  QuaternaryHeading,
  SecondaryHeading,
  TertiaryHeading,
} from "../../common/heading.component"
import { Image } from "../../common/image.component"
import StandardSection, {
  SectionImage,
  SectionImageDescription,
  SectionLayout,
} from "../../common/sections/section.component"
import { Footnote, Image as ImageType } from "./blog.interface"
import ResponsiveImageResolver from "../../../resolvers/responsive-image/responsive-image.resolver"

const RawImage = styled(Image)`
  * {
    padding: 0 !important;
  }

  img {
    position: relative;
  }
`
/* vvvvv For now we set all images to be zoomable as we wait for plugin update to utilize Tags feature vvvvv */

// const heightLimitImageStyles = css`
//   margin: -15px;
//   max-height: 70vh;
//   width: calc(100% + 30px);
//   --max-width: calc(100% + 30px);

//   @media (min-height: 715px) {
//     max-height: 500px;
//   }

//   img {
//     width: auto;
//     margin: 15px;
//     max-width: 100%;
//     border-radius: 5px;
//     left: calc(50% - 15px);
//     transform: translateX(-50%);
//     max-height: calc(100% - 30px);
//     box-shadow: 0 2px 20px 0 ${({ theme }) => theme.color.lightShadow};
//   }
// `

// const BlogImage = styled(SectionImage)<{ heightLimit: boolean }>`
//   ${({ heightLimit }) => heightLimit && heightLimitImageStyles}
// `

// const BlogResponsiveImage = styled(ResponsiveImageResolver)<{
//   heightLimit: boolean
// }>`
//   ${({ heightLimit }) => heightLimit && heightLimitImageStyles}
// `

/* ^^^^ For now we set all images to be zoomable as we wait for plugin update to utilize Tags feature ^^^^ */

export const footnoteMaker = (
  { id, footnoteAccessedDate, footnoteLink, footnoteTitle }: Footnote,
  footnotes: Footnote[]
) => {
  const footnoteIndex = footnotes.findIndex((f: Footnote) => f.id === id)
  let version = "a"

  if (footnoteIndex === -1) {
    footnotes.push({
      id,
      footnoteAccessedDate,
      footnoteLink,
      footnoteTitle,
      occurrences: 1,
    })
  } else {
    const occurrences = footnotes[footnoteIndex].occurrences || 1
    footnotes[footnoteIndex].occurrences = occurrences + 1
    version = String.fromCharCode(97 + occurrences)
  }

  const currentIndex = footnoteIndex < 0 ? footnotes.length : footnoteIndex + 1
  return {
    currentIndex,
    version,
  }
}

const FootnoteSup = styled.sup`
  ${annotationStyles};
  font-size: 0.7rem;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    font-size: 0.7rem;
  }
  white-space: nowrap;

  a + a,
  & + sup {
    margin-left: 4px;
  }
  a[href^="#ft-"] {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    span[id]:target {
      + span {
        font-weight: 600;
      }
    }

    span {
      letter-spacing: 1px;
    }
  }
  color: ${({ theme }) => theme.color.tag};
  ${footnotesAnchors};
`

const FootnoteCmp: AppFunctionComponent<{
  currentIndex: number
  version: string
}> = ({ currentIndex, version }) => (
  <FootnoteSup>
    <NeutralRawLink to={`#ft-${currentIndex}-${version}`} target={"_self"}>
      <span id={`ref-${currentIndex}-${version}`} />
      <span>[{currentIndex}]</span>
    </NeutralRawLink>
  </FootnoteSup>
)

const makeImage = (target: ImageType) => {
  // This condition is needed to prevent build fails as much as possible (See MW2-314 for more info)
  if (!target) {
    return null
  }
  const { description } = target
  // const { aspectRatio } = target.image

  /* For now we set all images to be zoomable as we wait for plugin update to utilize Tags feature */

  // const containTag = (file: Image, tag: string) => {
  //   return Boolean(file.metadata.tags?.find((item) => item.sys.id === tag))
  // }

  return (
    <Zoomable
      /* For now we set all images to be zoomable as we wait for plugin update to utilize Tags feature */

      // preventZoom={!containTag(target, "zoom")}
      preventZoom={false}
      zoomedContent={<RawImage image={target.gatsbyImageData} alt="" />}
    >
      <StandardSection layout={SectionLayout.TextBigImage}>
        <SectionImage
          image={target.gatsbyImageData}
          disableShadow
          // heightLimit={aspectRatio <= 1.3}
          alt=""
        />
        {description && (
          <SectionImageDescription>{description}</SectionImageDescription>
        )}
      </StandardSection>
    </Zoomable>
  )
}

const makeResponsiveImage = (image: ResponsiveImage) => {
  // const aspectRatio = image.desktopImage?.fluid?.aspectRatio || 0
  return (
    <StandardSection layout={SectionLayout.TextBigImage}>
      <ResponsiveImageResolver
        {...image}
        // heightLimit={aspectRatio <= 1.3}
      />
    </StandardSection>
  )
}

const transformTranslatedVideo = (video: Video): Video => {
  return {
    ...video,
    controlsPanel: video.controlsPanel ? video.controlsPanel : true, // TODO: change it when all old videos will have updated properties
  }
}

const makeVideo = (video: Video) => (
  <StandardSection layout={SectionLayout.TextBigImage}>
    <VideoResolver {...transformTranslatedVideo(video)} />
  </StandardSection>
)

export const renderBlogPost = (text: RenderRichText) => {
  const footnotes: Footnote[] = []

  const blogPostRichTextOptions: Options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children: ReactNode) => (
        <SecondaryHeading renderAs={"h2"}>{children}</SecondaryHeading>
      ),
      [BLOCKS.HEADING_2]: (_, children: ReactNode) => (
        <TertiaryHeading renderAs={"h3"}>{children}</TertiaryHeading>
      ),
      [BLOCKS.HEADING_3]: (_, children: ReactNode) => (
        <QuaternaryHeading renderAs={"h4"}>{children}</QuaternaryHeading>
      ),
      [BLOCKS.QUOTE]: (_, children: ReactNode) => <Quote>{children}</Quote>,
      [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
        const { target } = node.data
        // This condition is needed to prevent build fails as much as possible (See MW2-314 for more info)
        if (target) {
          return makeImage(target)
        }
        return null
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: Node) => {
        const { target } = node.data
        const {
          internal: { type },
        } = target

        if (type === "ContentfulVideo") {
          return makeVideo(target)
        } else if (type === "ContentfulResponsiveImage") {
          return makeResponsiveImage(target)
        } else {
          return ""
        }
      },
      [INLINES.HYPERLINK]: (node: Node, children: ReactNode) => (
        <RawLink to={node.data.uri}>{children}</RawLink>
      ),
      ...embeddedEntryMaker(footnotes),
    },
  }
  return {
    footnotes,
    blogPostContent: renderRichText(text, blogPostRichTextOptions),
  }
}

export const embeddedEntryMaker = (footnotes: Footnote[]) => ({
  [INLINES.EMBEDDED_ENTRY]: (node: Node) => {
    const { footnoteAccessedDate, footnoteLink, footnoteTitle, internal, id } =
      node.data.target

    switch (internal?.type) {
      case "ContentfulFootnote":
        return (
          <FootnoteCmp
            {...footnoteMaker(
              {
                footnoteAccessedDate,
                ...footnoteLink,
                ...footnoteTitle,
                id,
              },
              footnotes
            )}
          />
        )
      case "ContentfulButton":
        return mapEmbeddedEntryToButtonLink(node)
      default:
        return ""
    }
  },
})
