import { AppFunctionComponent } from "../../types"
import React, { useContext } from "react"
import { ThemeContext } from "styled-components"
import {
  SectionImage,
  SectionImageDescription,
  LinkedSectionImage,
} from "../../components/common/sections/section.component"
import { generateSources } from "../../helpers/images"
import { Theme } from "../../theme"
import { GatsbyImage, ResponsiveImage } from "../../types"

export const resolveImages = (
  image: ResponsiveImage,
  theme: Theme
): [GatsbyImage?, number?][] => {
  const { mobileImage, tabletImage, desktopImage } = image
  const { tabletHorizontal, mobileHorizontal } = theme.breakpoint

  return [
    [desktopImage],
    [tabletImage, tabletImage ? tabletHorizontal : mobileHorizontal],
    [mobileImage, mobileHorizontal],
  ]
}

const resolveAlt = ({
  mobileImage,
  tabletImage,
  desktopImage,
}: ResponsiveImage) => {
  const image = [desktopImage, tabletImage, mobileImage].find((img) => img)
  return image?.description
}

const Wrapper: AppFunctionComponent<
  Pick<ResponsiveImage, "linkUrl" | "linkText">
> = ({ linkUrl, linkText, children }) => {
  if (linkUrl && linkText) {
    return (
      <LinkedSectionImage to={linkUrl} aria-label={linkText}>
        {children}
      </LinkedSectionImage>
    )
  }
  return <>{children}</>
}

const ResponsiveImageResolver: AppFunctionComponent<ResponsiveImage> = ({
  className,
  ...image
}) => {
  const theme = useContext(ThemeContext)

  const images = resolveImages(image, theme)
  const alt = resolveAlt(image)

  return (
    <Wrapper linkUrl={image.linkUrl} linkText={image.linkText}>
      <SectionImage
        image={generateSources(images)}
        className={className}
        disableShadow={!image.shadow}
        contain={image.contain}
        alt={alt}
      />
      <SectionImageDescription>{image.description}</SectionImageDescription>
    </Wrapper>
  )
}

export default ResponsiveImageResolver
